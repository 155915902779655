<template>
  <base-card>
    <v-card-text class="v-card__text--dense">
      <div class="d-flex justify-space-between align-center">
        <div class="card-title ma-0 text-capitalize ">{{ $t(`app.sections.${currentRouteName}`) }}</div>
        <v-breadcrumbs :items="getBreadcrumbs()">
          <template #divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </div>
    </v-card-text>
  </base-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    breadcrumbs: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  computed: {
    ...mapGetters("app", ["appDomain", "appTitle"]),
    appType() {
      return this.$route.params.app;
    },
    currentRouteName() {
      return this.$route.name;
    }
  },
  methods: {
    getBreadcrumbs() {
      let items = [
        {
          text: this.$t("app.sections.dashboard"),
          disabled: false,
          to: { name: "dashboard" }
        }
      ];

      if (this.appType) {
        items.push({
          text: this.appTitle(this.appType),
          disabled: true
        });
      }

      return items.concat(this.breadcrumbs);
    }
  }
};
</script>
