<template>
  <v-text-field
    v-model="fieldValue"
    v-bind="$attrs"
    :type="type"
    :filled="disabled"
    :disabled="disabled"
    :label="label"
    outlined
    dense
    class="p-0"
    persistent-hint
    @keyup.enter="onEnter"
  ></v-text-field>
</template>

<script>
export default {
  name: "CaTextField",
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: "Field label"
    }
  },
  data() {
    return {
      fieldValue: this.value
    };
  },
  watch: {
    value(val) {
      this.fieldValue = val;
    },
    fieldValue(val) {
      this.$emit("input", val);
    }
  },
  methods: {
    onEnter() {
      this.$emit("onEnter", this.fieldValue);
    }
  }
};
</script>
