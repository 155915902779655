<template>
  <v-row>
    <v-col :cols="12">
      <page-header-with-breadcrumbs :breadcrumbs="breadcrumbs" />
    </v-col>

    <v-col class="mt-0 pt-0" :cols="12">
      <v-row class="mt-0 pt-0">
        <v-col class="my-0 py-0" :sm="6">
          <h6 class="text-caption text--disabled text-uppercase mt-2">{{ t("summary") }}</h6>
        </v-col>
        <v-col class="my-0 pt-0 pb-2 text-right">
          <v-chip color="white" class="mr-2" @click="refreshData"
            >{{ $t("common.refresh") }}
            <v-icon right color="blue light" :size="16">
              mdi-refresh
            </v-icon></v-chip
          >
        </v-col>
      </v-row>

      <v-card flat>
        <v-card-text class="py-3">
          <v-row v-if="loadingSummaryData">
            <v-col sm="6" md="3" lg="3">
              <v-skeleton-loader type="actions" :loading="loadingSummaryData"> </v-skeleton-loader>
            </v-col>
            <v-col sm="6" md="3" lg="3">
              <v-skeleton-loader type="actions" :loading="loadingSummaryData"> </v-skeleton-loader>
            </v-col>
            <v-col sm="6" md="3" lg="3">
              <v-skeleton-loader type="actions" :loading="loadingSummaryData"> </v-skeleton-loader>
            </v-col>
            <v-col sm="6" md="3" lg="3">
              <v-skeleton-loader type="actions" :loading="loadingSummaryData"> </v-skeleton-loader>
            </v-col>
          </v-row>

          <v-row v-if="!loadingSummaryData" class="align-center">
            <v-col v-for="(summaryItem, index) in summaryItems" :key="index" sm="6" md="3" lg="3">
              <div class="d-flex flex-center align-center">
                <div>
                  <p class="mb-1  mr-4">{{ summaryItem.label }}</p>
                </div>
                <h1 class="text-h4 font-weight-thin mb-0">{{ summaryItem.value }}</h1>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col :cols="12">
      <v-card flat>
        <v-card-text>
          <v-row>
            <v-col :sm="3">
              <h6 class="text-caption text--disabled text-uppercase">
                {{ t("detailed_statistics") }}
              </h6>
            </v-col>
            <v-col :sm="9" class="py-0 my-0">
              <div class="d-flex justify-end align-center">
                <div>
                  <v-text-field
                    v-model="filters.search"
                    :placeholder="t('search_by_user')"
                    append-icon="mdi-magnify"
                    @change="onSearch"
                  ></v-text-field>
                </div>
                <div class="ml-5">
                  <v-switch
                    v-model="filters.users_with_earnings"
                    :label="t('users_with_earnings')"
                    @change="loadData"
                  />
                </div>
              </div>
            </v-col>
          </v-row>

          <div>
            <v-data-table
              :loading="loadingData"
              :headers="dataTable.headers"
              :items="dataTableItems"
              :sort-by.sync="sort.by"
              :sort-desc.sync="sort.desc"
              :server-items-length="totalItems"
              :items-per-page="itemsPerPage"
              :footer-props="dataTable.footerProps"
              @update:sort-desc="onUpdateSortDesc"
              @update:items-per-page="onUpdateItemsPerPage"
              @update:page="onPageUpdate"
            >
              <template #item.registration_date="{item}">
                <span class="text-lowercase">{{ formatDate(item.registration_date) }}</span>
              </template>

              <template #item.today_earnings="{item}">
                {{ item.today_earnings > 0 ? t("earnings", { value: item.today_earnings }) : 0 }}
              </template>

              <template #item.weekly_earnings="{item}">
                {{ item.weekly_earnings > 0 ? t("earnings", { value: item.weekly_earnings }) : 0 }}
              </template>

              <template #item.total_earnings="{item}">
                {{ item.total_earnings > 0 ? t("earnings", { value: item.total_earnings }) : 0 }}
              </template>
            </v-data-table>
          </div>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col v-if="apptype === 'fd1'" :cols="12">
      <v-card flat class="pb-7">
        <v-card-title>{{ t("referrals_promo_meterials") }}</v-card-title>

        <v-card-text class="mb-0 pb-0">
          <ca-text-field v-model="promo.refLink" :label="t('referral_link')" />
        </v-card-text>

        <v-card-title class="mt-0 pt-0 text-muted">{{ t("referrals_banners") }}</v-card-title>

        <v-card-text v-for="(banner, index) in promo.refBanners" :key="index" class="mb-0 pb-0">
          <div>{{ banner.width }}x{{ banner.height }}</div>
          <div>
            <iframe
              :width="banner.width"
              :height="banner.height"
              :src="bannerPlaceholder(banner.width, banner.height)"
              frameborder="0"
              scrolling="no"
            ></iframe>
          </div>

          <div class="mt-2">
            <v-textarea
              :label="t('banner_code')"
              hide-details
              rows="2"
              dense
              outlined
              style="max-width: 728px; font-size: 13px;"
              class="direction-ltr"
              :value="bannerCode(banner.width, banner.height)"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import PageHeaderWithBreadcrumbs from "../../components/PageHeaderWithBreadcrumbs";
import CaTextField from "../../components/form/fields/TextFieldDenseAndFilled";

export default {
  components: { CaTextField, PageHeaderWithBreadcrumbs },
  metaInfo() {
    return {
      title: this.$t("titles.referrals")
    };
  },
  data() {
    return {
      loadingSummaryData: true,
      loadingData: false,
      dataTableItems: [],
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 0,
      sort: {
        by: [],
        desc: []
      },
      filters: {
        users_with_earnings: true,
        search: ""
      },
      summary: {
        totalReferrals: 0,
        firstLevel: 0,
        secondLevel: 0,
        totalEarned: 0
      },
      appUserId: null,
      promo: {
        refLink: null,
        promoDomain: null,
        refBanners: [
          {
            width: 970,
            height: 250
          },
          {
            width: 728,
            height: 90
          },
          {
            width: 640,
            height: 200
          },
          {
            width: 468,
            height: 60
          },
          {
            width: 336,
            height: 280
          },
          {
            width: 300,
            height: 600
          },
          {
            width: 300,
            height: 250
          },
          {
            width: 240,
            height: 400
          },
          {
            width: 160,
            height: 600
          }
        ]
      }
    };
  },
  computed: {
    dataTable() {
      return {
        headers: [
          {
            text: this.t("data_table.fields.user"),
            value: "login",
            sortable: true
          },
          {
            text: this.t("data_table.fields.referral_level"),
            value: "ref_level",
            sortable: true
          },
          {
            text: this.t("data_table.fields.registration_date"),
            value: "registration_date",
            sortable: true
          },
          {
            text: this.t("data_table.fields.today_earnings"),
            value: "today_earnings",
            sortable: true
          },
          {
            text: this.t("data_table.fields.weekly_earnings"),
            value: "weekly_earnings",
            sortable: true
          },
          {
            text: this.t("data_table.fields.total_earnings"),
            value: "total_earnings",
            sortable: true
          },
          {
            text: this.t("data_table.fields.ref_percent"),
            value: "ref_percent",
            sortable: true
          }
        ],
        footerProps: {
          itemsPerPageOptions: [5, 10, 25, 100]
        }
      };
    },
    apptype() {
      return this.$route.params.app;
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("app.sections.referrals"),
          disabled: true
        }
      ];
    },
    summaryItems() {
      return [
        {
          label: this.t("total_referrals"),
          value: this.summary.totalReferrals
        },
        {
          label: this.t("first_level"),
          value: this.summary.firstLevel
        },
        {
          label: this.t("second_level"),
          value: this.summary.secondLevel
        },
        {
          label: this.t("total_earned"),
          value: this.t("total_earned_value", { value: this.summary.totalEarned })
        }
      ];
    },
    userData() {
      return this.$store.state.auth.userData;
    }
  },
  watch: {
    $route() {
      this.refreshData();
    }
  },

  mounted() {
    this.loadSummaryData();
    this.loadData();

    this.appUserId = this.$store.state.auth.userData["app_users_id"][this.apptype];
    this.promo.refLink = `${this.$store.state.auth.userData.ref_link_domain}/partner/?PHPSESUD=${this.appUserId}`;
    this.promo.promoDomain = this.$store.state.auth.userData.promo_domain;
  },

  methods: {
    loadData() {
      if (this.loadingData) {
        return;
      }

      this.loadingData = true;

      this.axios
        .get("/referrals/stats", {
          params: {
            app: this.apptype,
            page: this.currentPage,
            per_page: this.itemsPerPage,
            sort_key: this.sort.by.length > 0 ? this.sort.by[0] : null,
            sort_dir: this.sort.desc.length > 0 ? (this.sort.desc[0] ? "desc" : "asc") : null,
            users_with_earnings: this.filters.users_with_earnings ? 1 : 0,
            search: this.filters.search
          }
        })
        .then(response => {
          this.dataTableItems = response.data.data;
          this.itemsPerPage = response.data.meta.per_page;
          this.totalItems = response.data.meta.total;

          this.loadingData = false;
        })
        .catch(() => {
          this.loadingData = false;

          this.$store.dispatch("app/showDialog", {
            type: "error",
            title: "Error",
            message: "Failed getting data"
          });
        });
    },
    loadSummaryData() {
      this.loadingSummaryData = true;

      this.axios.get("/referrals/summary?app=" + this.apptype).then(response => {
        this.summary.totalReferrals = response.data.total_referrals_count;
        this.summary.firstLevel = response.data.first_level_count;
        this.summary.secondLevel = response.data.second_level_count;
        this.summary.totalEarned = response.data.total_earned;

        this.loadingSummaryData = false;
      });
    },
    onUpdateItemsPerPage(itemsPerPage) {
      if (this.loadingData) {
        return;
      }

      this.itemsPerPage = itemsPerPage;
      this.loadData();
    },
    onPageUpdate(page) {
      if (this.loadingData) {
        return;
      }

      this.currentPage = page;
      this.loadData();
    },
    onUpdateSortDesc() {
      if (this.loadingData) {
        return;
      }

      this.loadData();
    },
    refreshData() {
      this.loadSummaryData();
      this.loadData();
    },
    formatDate(dateString) {
      const date = new Date(dateString);

      return date.getDate() + " " + this.$t("app.months.short." + (date.getMonth() + 1)) + " " + date.getFullYear();
    },
    onSearch(search) {
      this.loadData();
    },
    bannerPlaceholder(width, height) {
      return `${this.promo.promoDomain}/ref/html/${this.userData.lang}/${width}-${height}/${this.appUserId}`;
    },
    bannerCode(width, height) {
      return (
        `<iframe width='${width}' height='${height}'` +
        ` src='${this.bannerPlaceholder(width, height)}' ` +
        " frameborder='0' scrolling='no'>" +
        "</iframe>"
      );
    },
    t(key, params) {
      return this.$t("pages.referrals." + key, params);
    }
  }
};
</script>
